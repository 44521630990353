import { useGetFuturePositions } from "../api/positions";
import DataTable, { TableColumn } from "react-data-table-component";
import Loader from "../components/Loader";
import formatter from "../utils/format";
import { IFuturePosition } from "src/types/position";
import {
  format,
  zeroAsDash,
  shortId,
  date,
  customStyles,
  updateOnFlash,
} from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IFuturePosition>[] = [
  // {
  //   name: "ID",
  //   selector: (row) => row.id,
  //   sortable: true,
  //   // format: shortId("id"),
  // },
  {
    name: "Exchange",
    selector: (row) => row.exchange,
    sortable: true,
  },
  {
    name: "Asset",
    selector: (row) => row.symbol,
    sortable: true,
  },
  {
    name: "Side",
    selector: (row) => row.side,
    sortable: true,
    cell: updateOnFlash,
  },
  {
    name: "Units",
    selector: (row) => row.contracts,
    sortable: true,
    format: format(formatter.decimal, "contracts"),
    conditionalCellStyles: [zeroAsDash("contracts")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Collateral",
    selector: (row) => row.collateral,
    sortable: true,
    format: format(formatter.decimal, "collateral"),
    conditionalCellStyles: [zeroAsDash("collateral")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Notional",
    selector: (row) => row.notional,
    sortable: true,
    format: format(formatter.decimal, "notional"),
    conditionalCellStyles: [zeroAsDash("notional")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Margin Ratio",
    selector: (row) => row.marginRatio,
    sortable: true,
    format: format(formatter.decimal, "marginRatio"),
    conditionalCellStyles: [zeroAsDash("marginRatio")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Maintenance Margin",
    selector: (row) => row.maintenanceMargin,
    sortable: true,
    format: format(formatter.decimal, "maintenanceMargin"),
    conditionalCellStyles: [zeroAsDash("maintenanceMargin")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Liquidation Price",
    selector: (row) => row.liquidationPrice,
    sortable: true,
    format: format(formatter.decimal, "liquidationPrice"),
    conditionalCellStyles: [zeroAsDash("liquidationPrice")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Mark Price",
    selector: (row) => row.markPrice,
    sortable: true,
    format: format(formatter.decimal, "markPrice"),
    conditionalCellStyles: [zeroAsDash("markPrice")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Last Updated",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
    cell: updateOnFlash,
  },
];

export default function FuturePositions() {
  const { positions, positionsLoading } = useGetFuturePositions();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={positions}
      responsive
      pagination
      dense
      progressPending={positionsLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
}
