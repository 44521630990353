import { useGetClosedPositions } from "../api/positions";
import DataTable, { TableColumn } from "react-data-table-component";
import Loader from "../components/Loader";
import formatter from "../utils/format";
import { IPosition } from "src/types/position";
import {
  format,
  zeroAsDash,
  shortId,
  date,
  customStyles,
} from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IPosition>[] = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    format: shortId("id"),
  },
  {
    name: "Asset",
    selector: (row) => row.asset,
    sortable: true,
  },
  {
    name: "Units",
    selector: (row) => row.units,
    sortable: true,
    format: format(formatter.decimal, "units"),
    conditionalCellStyles: [zeroAsDash("units")],
  },
  {
    name: "State",
    selector: (row) => row.state,
    sortable: true,
  },
  {
    name: "Fee",
    selector: (row) => row.fee,
    sortable: true,
    format: format(formatter.decimal, "fee"),
    conditionalCellStyles: [zeroAsDash("fee")],
  },
  {
    name: "Buy Price",
    selector: (row) => row["entry.price"],
    sortable: true,
    format: format(formatter.decimal, "entry.price"),
    conditionalCellStyles: [zeroAsDash("entry.price")],
  },
  {
    name: "Buy Filled",
    selector: (row) => row["entry.filled"],
    sortable: true,
    format: format(formatter.decimal, "entry.filled"),
    conditionalCellStyles: [zeroAsDash("entry.filled")],
  },
  {
    name: "Sell Price",
    selector: (row) => row["exit.price"],
    sortable: true,
    format: format(formatter.decimal, "exit.price"),
    conditionalCellStyles: [zeroAsDash("exit.price")],
  },
  {
    name: "Sell Filled",
    selector: (row) => row["exit.filled"],
    sortable: true,
    format: format(formatter.decimal, "exit.filled"),
    conditionalCellStyles: [zeroAsDash("exit.filled")],
  },
  {
    name: "Quote Profit",
    selector: (row) => row.quoteProfit,
    sortable: true,
    format: format(formatter.decimal, "quoteProfit"),
    conditionalCellStyles: [zeroAsDash("quoteProfit")],
  },
  {
    name: "Base Profit",
    selector: (row) => row.baseProfit,
    sortable: true,
    format: format(formatter.decimal, "baseProfit"),
    conditionalCellStyles: [zeroAsDash("baseProfit")],
  },
  {
    name: "Last Updated",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
  },
];

export default function ClosedPositions() {
  const { positions, positionsLoading } = useGetClosedPositions();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={positions}
      responsive
      pagination
      dense
      progressPending={positionsLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      // highlightOnHover
      customStyles={customStyles}
    />
  );
}
