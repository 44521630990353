import useSWR from "swr";
import { useMemo } from "react";
// utils
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
// types
import { IBalance, ITransfer } from "src/types/balance";

// ----------------------------------------------------------------------

export function useGetBalances() {
  const URL = endpoints.balances.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });

  const memoizedValue = useMemo(
    () => ({
      balances: (data as IBalance[]) || [],
      balancesLoading: isLoading,
      balancesError: error,
      balancesValidating: isValidating,
      balancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetTransfers() {
  const URL = endpoints.balances.tranfers;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });

  const memoizedValue = useMemo(
    () => ({
      transfers: (data as ITransfer[]) || [],
      transfersLoading: isLoading,
      transfersError: error,
      transfersValidating: isValidating,
      transfersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
