export const futures = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/asset",
            },
            {
              type: "Group",
              label: "Spread",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/gammaOne",
                },
                {
                  type: "Control",
                  scope: "#/properties/gammaTwo",
                },
                {
                  type: "Control",
                  scope: "#/properties/spread.min",
                },
                {
                  type: "Control",
                  scope: "#/properties/spread.max",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.exchange",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.feed",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.symbol",
                },
              ],
            },
          ],
        },
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Group",
              label: "Inventory",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/inventory.max",
                },
                {
                  type: "Control",
                  scope: "#/properties/inventory.spot.skew",
                },
                {
                  type: "Control",
                  scope: "#/properties/inventory.swap.skew",
                },
              ],
            },
            {
              type: "Group",
              label: "Sizing",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/sizing.min",
                },
                {
                  type: "Control",
                  scope: "#/properties/sizing.max",
                },
                {
                  type: "Control",
                  scope: "#/properties/sizing.orderLimit",
                },
              ],
            },
            {
              type: "Group",
              label: "General Settings",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/updateFrequency",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/markets",
    },
  ],
};
