import OpenPositions from "../sections/OpenPositions";
import Orders from "../sections/Orders";
import ClosedPositions from "../sections/ClosedPositions";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { OrdersQuery } from "src/api/orders";
import { Helmet } from "react-helmet";
import FuturePositions from "../sections/FuturePositions";
import FundingFees from "../sections/FundingFees";

export default function Positions() {
  return (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="grid gap-px w-full">
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Positions</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <FuturePositions />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Funding Fees</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <FundingFees />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
