import useSWR from "swr";
import { useMemo } from "react";
// utils
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
// types
import { IOrder } from "src/types/order";

// ----------------------------------------------------------------------

export enum OrdersQuery {
  Open,
  Filled,
  Canceled,
  Rejected,
}

export function useGetOrders(type: OrdersQuery) {
  const URLS = {
    [OrdersQuery.Open]: endpoints.orders.open,
    [OrdersQuery.Filled]: endpoints.orders.closed,
    [OrdersQuery.Rejected]: endpoints.orders.rejected,
  };

  const { data, isLoading, error, isValidating } = useSWR(URLS[type], fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });

  const memoizedValue = useMemo(
    () => ({
      orders: (data as IOrder[]) || [],
      ordersLoading: isLoading,
      ordersError: error,
      ordersValidating: isValidating,
      ordersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating, type]
  );

  return memoizedValue;
}
