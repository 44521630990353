import { Navbar } from "../components/Navbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseAuth, useAuthState } from "../firebase";

export const PlatformLayout = ({ item }) => {
  const [user, signInLoading] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !signInLoading) {
      navigate("/login");
    }
  }, [user, signInLoading]);
  return (
    <div className="flex bg-black min-h-screen w-screen">
      <Navbar />
      <div className="w-full">{item}</div>
    </div>
  );
};
