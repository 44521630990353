import { useState } from "react";
import { useGetInstances } from "../api/instances";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import Loader from "../components/Loader";
import { IInstance } from "src/types/instance";
import formatter from "../utils/format";
import { format, zeroAsDash, customStyles } from "../utils/table";
import { JsonForms } from "@jsonforms/react";
import { vanillaCells, vanillaRenderers } from "@jsonforms/vanilla-renderers";
import axios from "../utils/axios";
import { enqueueSnackbar } from "notistack";
import CustomTableRenderer, {
  customTableArrayControlTester,
} from "../components/CustomTableRenderer";
import EmptyTable from "src/components/EmptyTable";
import * as schemas from "../schemas";

const columns: TableColumn<IInstance>[] = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Asset",
    selector: (row) => row.asset,
    sortable: true,
  },
  {
    name: "Reference Exchange",
    selector: (row) => row["parameters.reference.feed"],
    sortable: true,
  },
  {
    name: "Reference Symbol",
    selector: (row) => row["parameters.reference.symbol"],
    sortable: true,
  },
  {
    name: "γ One",
    selector: (row) => row["parameters.gammaOne"],
    sortable: true,
    format: format(formatter.decimal, "parameters.gammaOne"),
    // conditionalCellStyles: [zeroAsDash("gammaOne")],
    right: true,
  },
  {
    name: "γ Two",
    selector: (row) => row["parameters.gammaTwo"],
    sortable: true,
    format: format(formatter.decimal, "parameters.gammaTwo"),
    // conditionalCellStyles: [zeroAsDash("gammaTwo")],
    right: true,
  },
  {
    name: "Sizing Min",
    selector: (row) => row["parameters.sizing.min"],
    sortable: true,
    format: format(formatter.decimal, "parameters.sizing.min"),
    // conditionalCellStyles: [zeroAsDash("sizing.min")],
    right: true,
  },
  {
    name: "Sizing Max",
    selector: (row) => row["parameters.sizing.max"],
    sortable: true,
    format: format(formatter.decimal, "parameters.sizing.max"),
    conditionalCellStyles: [zeroAsDash("parameters.sizing.max")],
    right: true,
  },
  {
    name: "α",
    selector: (row) => row["parameters.alpha"],
    sortable: true,
    format: format(formatter.decimal, "parameters.alpha"),
    // conditionalCellStyles: [zeroAsDash("alpha")],
    right: true,
  },
  {
    name: "β",
    selector: (row) => row["parameters.beta"],
    sortable: true,
    format: format(formatter.decimal, "parameters.beta"),
    // conditionalCellStyles: [zeroAsDash("beta")],
    right: true,
  },
  {
    name: "Skew",
    selector: (row) => row["parameters.skewInitialQuotes"],
    sortable: true,
    format: format(formatter.decimal, "parameters.skewInitialQuotes"),
    conditionalCellStyles: [zeroAsDash("parameters.skewInitialQuotes")],
    right: true,
  },
  {
    name: "Grace Period",
    selector: (row) => row["parameters.gracePeriod"],
    sortable: true,
    format: format(formatter.decimal, "parameters.gracePeriod"),
    conditionalCellStyles: [zeroAsDash("parameters.gracePeriod")],
    right: true,
  },
  {
    name: "Max Time Open",
    selector: (row) => row["parameters.maxTimeOpen"],
    sortable: true,
    format: format(formatter.decimal, "parameters.maxTimeOpen"),
    conditionalCellStyles: [zeroAsDash("parameters.maxTimeOpen")],
    right: true,
  },
];

const ExpandedComponent: React.FC<ExpanderComponentProps<IInstance>> = ({
  data,
}) => {
  const [formData, setFormData] = useState(data.parameters);
  const [formErrors, setFormErrors] = useState([]);
  const ui = {
    "market-marker": schemas.marketMaker,
    "secondary-market-maker": schemas.marketMaker,
    futures: schemas.futures,
    "smart-order-router": schemas.smartOrderRouter,
    trifecta: schemas.trifecta,
  };
  return (
    <div className="border-b border-black">
      <div className="p-4">
        <JsonForms
          schema={schemas.definitions}
          uischema={ui[data.strategy]}
          data={formData}
          renderers={[
            ...vanillaRenderers,
            {
              tester: customTableArrayControlTester,
              renderer: CustomTableRenderer,
            },
          ]}
          cells={vanillaCells}
          onChange={({ data, errors }) => {
            setFormData(data);
            setFormErrors(errors);
          }}
        />
      </div>
      <div className="px-6 py-4 border-t border-black mt-2 flex">
        <div className="space-x-4 flex-1">
          <button
            className="bg-primary-500 hover:bg-primary-600 text-white font-medium py-2 px-4"
            onClick={() => {
              console.log(formData);
              console.log(formErrors);
              axios.post(`/instances/${data.id}/parameters`, formData);
              enqueueSnackbar("Changes saved", { key: "saved-parameters" });
            }}
          >
            Save Changes
          </button>
        </div>
        <div className="space-x-4">
          <button
            className="bg-gray-600 text-white font-medium py-2 px-4"
            onClick={() => {
              // axios.post(`/instances/${data.id}/parameters`, formData);
            }}
          >
            Pause
          </button>
          <button
            className="bg-gray-600 text-white font-medium py-2 px-4"
            onClick={() => {
              // axios.post(`/instances/${data.id}/parameters`, formData);
            }}
          >
            Stop
          </button>
          <button
            className="bg-gray-600 text-white font-medium py-2 px-4"
            onClick={() => {
              // axios.post(`/instances/${data.id}/parameters`, formData);
            }}
          >
            Restart
          </button>
        </div>
      </div>
    </div>
  );
};

export default function InstanceParameters() {
  const { instances, instancesLoading } = useGetInstances();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={instances}
      responsive
      pagination
      dense
      progressPending={instancesLoading}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
}
