import Instances from "../sections/Instances";
import OpenPositions from "../sections/OpenPositions";
import ClosedPositions from "../sections/ClosedPositions";
import RequestForQuotes from "../sections/RequestForQuotes";
import Balances from "../sections/Balances";
import KeyMetrics from "../sections/KeyMetrics";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { Helmet } from "react-helmet";

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="grid gap-px w-full">
        <Group key="metrics">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Overview</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <KeyMetrics />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="instances">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Instances</Tab>
              <Tab className={tabsTheme.tab}>Balances</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <Instances />
              </Tab.Panel>
              <Tab.Panel className={tabsTheme.panel}>
                <Balances />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Open Positions</Tab>
              <Tab className={tabsTheme.tab}>RFQs</Tab>
              <Tab className={tabsTheme.tab}>Closed Positions</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <OpenPositions />
              </Tab.Panel>
              <Tab.Panel className={tabsTheme.panel}>
                <RequestForQuotes />
              </Tab.Panel>
              <Tab.Panel className={tabsTheme.panel}>
                <ClosedPositions />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
