import Instances from "../sections/Instances";
import Balances from "../sections/Balances";
import OrgBalances from "../sections/OrgBalances";
import Settlements from "../sections/Settlements";
import Transfers from "../sections/Transfers";
import KeyMetrics from "../sections/KeyMetrics";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { Helmet } from "react-helmet";

export default function Treasury() {
  return (
    <>
      <Helmet>
        <title>Treasury</title>
      </Helmet>
      <div className="grid gap-px w-full">
        {/* <Group key="metrics">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Overview</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <KeyMetrics />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group> */}
        {/* <Group key="instances">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Instances</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <Instances />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group> */}
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>External Transfers</Tab>
            </Tab.List>

            <Transfers />
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
