export default function Logo({
  size,
  className,
}: {
  size: number;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 169 170"
      className={className}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M123.506 78.004c0 25.13-20.372 45.502-45.502 45.502-25.13 0-45.503-20.372-45.503-45.502 0-25.13 20.372-45.503 45.503-45.503 25.13 0 45.502 20.372 45.502 45.503Zm6.492 58.148c-13.8 12.347-32.02 19.855-51.994 19.855C34.924 156.007 0 121.084 0 78.003 0 34.924 34.923 0 78.004 0c43.08 0 78.003 34.923 78.003 78.004 0 19.978-7.511 38.202-19.862 52.002h32.854v39.002h-39.001v-32.856Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
