export const definitions = {
  type: "object",
  properties: {
    strategy: {
      type: "string",
    },
    asset: {
      type: "string",
    },
    gammaOne: {
      type: "number",
    },
    gammaTwo: {
      type: "number",
    },
    alpha: {
      type: "number",
    },
    beta: {
      type: "number",
    },
    "sizing.min": {
      type: "number",
    },
    "spread.min": {
      type: "number",
    },
    "spread.max": {
      type: "number",
    },
    "sizing.max": {
      type: "number",
    },
    "sizing.orderLimit": {
      type: "number",
    },
    "inventory.max": {
      type: "number",
    },
    "inventory.skew": {
      type: "number",
    },
    "inventory.spot.skew": {
      type: "number",
    },
    "inventory.swap.skew": {
      type: "number",
    },
    skewInitialQuote: {
      type: "number",
    },
    skewTakeProfit: {
      type: "number",
    },
    maxTimeOpen: {
      type: "number",
    },
    gracePeriod: {
      type: "number",
    },
    maxUntouchedPositions: {
      type: "number",
    },
    decayTimeout: {
      type: "number",
    },
    maxLoss: {
      type: "number",
    },
    markets: {
      type: "array",
      items: {
        type: "object",
        properties: {
          enabled: {
            type: "boolean",
            label: "",
          },
          type: {
            type: "string",
            // readOnly: true,
            enum: ["PRIMARY", "SECONDARY", "WATCH"],
            className: "bg-gray-900",
          },
          "feed.type": {
            type: "string",
          },
          "exchange.type": {
            type: "string",
          },
          symbol: {
            type: "string",
          },
          "gammas.gammaOne": {
            type: "number",
          },
          "gammas.gammaTwo": {
            type: "number",
          },
          "fees.taker": {
            type: "number",
          },
          "fees.maker": {
            type: "number",
          },
          instrumentType: {
            type: "string",
            enum: ["SPOT", "SWAP"],
          },
        },
      },
    },
    quoteLevels: {
      type: "array",
      items: {
        type: "object",
        properties: {
          gammaFactor: {
            type: "number",
          },
          sizeFactor: {
            type: "number",
          },
        },
      },
    },
    "reference.exchange": {
      type: "string",
    },
    "reference.feed": {
      type: "string",
    },
    "reference.symbol": {
      type: "string",
    },
    "spot.reference.exchange": {
      type: "string",
    },
    "spot.reference.feed": {
      type: "string",
    },
    "spot.reference.symbol": {
      type: "string",
    },
    "swap.reference.exchange": {
      type: "string",
    },
    "swap.reference.feed": {
      type: "string",
    },
    "swap.reference.symbol": {
      type: "string",
    },
    "externalInfo.symbol": {
      type: "string",
    },
    "externalInfo.baseAsset": {
      type: "string",
    },
    "externalInfo.quoteAsset": {
      type: "string",
    },
    "externalInfo.maxOrderSize": {
      type: "number",
    },
    "externalInfo.minOrderSize": {
      type: "number",
    },
    "externalInfo.baseAssetPrecision": {
      type: "number",
    },
    "externalInfo.quoteAssetPrecision": {
      type: "number",
    },
    "externalInfo.maxOrderValue": {
      type: "number",
    },
    riskPremium: {
      type: "number",
    },
    rsiPremium: {
      type: "number",
    },
    "spreadCoefficients.alpha": {
      type: "number",
    },
    "spreadCoefficients.beta": {
      type: "number",
    },
    minSpread: {
      type: "number",
    },
    quoteExpirationTime: {
      type: "number",
    },
    updateFrequency: {
      type: "number",
    },
    maxRestingDistance: {
      type: "number",
    },
    untouchedThreshold: {
      type: "number",
    },
    biasFactor: {
      type: "number",
    },
    rsiRange: {
      type: "number",
    },
    pricingStrategy: {
      type: "string",
      enum: ["DEFAULT", "FAIL_SAFE"],
    },
    orderSizePremium: {
      type: "number",
    },
    maxPremium: {
      type: "number",
    },
    minPremium: {
      type: "number",
    },
  },
};
