import { useGetInstanceSignals } from "../api/instances";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import formatter from "../utils/format";
import {
  format,
  zeroAsDash,
  date,
  updateOnFlash,
  customStyles,
} from "../utils/table";
import { IInstanceSignals, IMarketSignals } from "src/types/instance";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IInstanceSignals>[] = [
  {
    name: "Instance",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Q",
    selector: (row) => row.relativeInventory,
    sortable: true,
    format: format(formatter.decimal, "relativeInventory"),
    conditionalCellStyles: [zeroAsDash("relativeInventory")],
    cell: updateOnFlash,
  },
  {
    name: "ABS(Q)",
    selector: (row) => row.inventory,
    sortable: true,
    format: format(formatter.decimal, "inventory"),
    conditionalCellStyles: [zeroAsDash("inventory")],
    cell: updateOnFlash,
  },
  // {
  //   name: "Reservation Price",
  //   selector: (row) => row.reservationPrice,
  //   sortable: true,
  //   format: format(formatter.decimal, "reservationPrice"),
  //   conditionalCellStyles: [zeroAsDash("reservationPrice")],
  //   cell: updateOnFlash,
  // },
  {
    name: "RSI",
    selector: (row) => row["signals.rsi"],
    sortable: true,
    format: format(formatter.decimal, "signals.rsi"),
    conditionalCellStyles: [zeroAsDash("signals.rsi")],
    cell: updateOnFlash,
  },
  {
    name: "σ",
    selector: (row) => row["signals.volatility"],
    sortable: true,
    format: format(formatter.decimal, "signals.volatility"),
    conditionalCellStyles: [zeroAsDash("signals.volatility")],
    cell: updateOnFlash,
  },
  {
    name: "Decay Timeout",
    selector: (row) => row.decayTimeout,
    sortable: true,
    format: format(formatter.noDecimals, "decayTimeout"),
    conditionalCellStyles: [zeroAsDash("decayTimeout")],
    cell: updateOnFlash,
  },
  // {
  //   name: "Take Profit",
  //   selector: (row) => row.optimalTakeProfit,
  //   sortable: true,
  //   format: format(formatter.decimal, "optimalTakeProfit"),
  //   conditionalCellStyles: [zeroAsDash("optimalTakeProfit")],
  //   cell: updateOnFlash,
  // },
  {
    name: "Average Ask",
    selector: (row) => row.averageAsk,
    sortable: true,
    format: format(formatter.decimal, "averageAsk"),
    conditionalCellStyles: [zeroAsDash("averageAsk")],
    cell: updateOnFlash,
  },
  {
    name: "Average Bid",
    selector: (row) => row.averageBid,
    sortable: true,
    format: format(formatter.decimal, "averageBid"),
    conditionalCellStyles: [zeroAsDash("averageBid")],
    cell: updateOnFlash,
  },
  {
    name: "Optimal Buy Size",
    selector: (row) => row.optimalBidSize,
    sortable: true,
    format: format(formatter.decimal, "optimalBidSize"),
    conditionalCellStyles: [zeroAsDash("optimalBidSize")],
    cell: updateOnFlash,
  },
  {
    name: "Optimal Sell Size",
    selector: (row) => row.optimalAskSize,
    sortable: true,
    format: format(formatter.decimal, "optimalAskSize"),
    conditionalCellStyles: [zeroAsDash("optimalAskSize")],
    cell: updateOnFlash,
  },
  {
    name: "Upper Limit",
    selector: (row) => row["signals.upperLimit"],
    sortable: true,
    format: format(formatter.decimal, "signals.upperLimit"),
    conditionalCellStyles: [zeroAsDash("signals.upperLimit")],
    cell: updateOnFlash,
  },
  {
    name: "Lower Limit",
    selector: (row) => row["signals.lowerLimit"],
    sortable: true,
    format: format(formatter.decimal, "signals.lowerLimit"),
    conditionalCellStyles: [zeroAsDash("signals.lowerLimit")],
    cell: updateOnFlash,
  },
  {
    name: "Last Update",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
    cell: updateOnFlash,
  },
];

const childColumns: TableColumn<IMarketSignals>[] = [
  {
    name: "Feed",
    selector: (row) => row.feed,
    sortable: true,
  },
  {
    name: "Exchange",
    selector: (row) => row.exchange,
    sortable: true,
  },
  {
    name: "M Price",
    selector: (row) => row.midPrice,
    sortable: true,
    format: format(formatter.decimal, "midPrice"),
    conditionalCellStyles: [zeroAsDash("midPrice")],
    cell: updateOnFlash,
  },
  {
    name: "R Price",
    selector: (row) => row.reservationPrice,
    sortable: true,
    format: format(formatter.decimal, "reservationPrice"),
    conditionalCellStyles: [zeroAsDash("reservationPrice")],
    cell: updateOnFlash,
  },
  {
    name: "Spread",
    selector: (row) => row.spread,
    sortable: true,
    format: format(formatter.decimal, "spread"),
    conditionalCellStyles: [zeroAsDash("spread")],
    cell: updateOnFlash,
  },
  {
    name: "Optimal Spread",
    selector: (row) => row.optimalSpread,
    sortable: true,
    format: format(formatter.decimal, "optimalSpread"),
    conditionalCellStyles: [zeroAsDash("optimalSpread")],
    cell: updateOnFlash,
  },
  {
    name: "OB α",
    selector: (row) => row.orderbookAlpha,
    sortable: true,
    format: format(formatter.decimal, "orderbookAlpha"),
    conditionalCellStyles: [zeroAsDash("orderbookAlpha")],
    cell: updateOnFlash,
  },
  {
    name: "Bias",
    selector: (row) => row.bias,
    sortable: true,
    format: format(formatter.decimal, "bias"),
    conditionalCellStyles: [zeroAsDash("bias")],
    cell: updateOnFlash,
  },
  {
    name: "κ",
    selector: (row) => row.kappa,
    sortable: true,
    format: format(formatter.decimal, "kappa"),
    conditionalCellStyles: [zeroAsDash("kappa")],
    cell: updateOnFlash,
  },
  {
    name: "α",
    selector: (row) => row.alpha,
    sortable: true,
    format: format(formatter.decimal, "alpha"),
    conditionalCellStyles: [zeroAsDash("alpha")],
    cell: updateOnFlash,
  },
];

const ExpandedComponent: React.FC<ExpanderComponentProps<IInstanceSignals>> = ({
  data,
}) => {
  return (
    <DataTable
      theme="qf"
      columns={childColumns}
      data={data.markets || []}
      responsive
      dense
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
};

export default function Instances() {
  const { instances, instancesLoading } = useGetInstanceSignals();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={instances}
      responsive
      pagination
      dense
      progressPending={instancesLoading}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
}
