export const marketMaker = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/asset",
            },
            {
              type: "Group",
              label: "Spread",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/gammaOne",
                },
                {
                  type: "Control",
                  scope: "#/properties/gammaTwo",
                },
                {
                  type: "Control",
                  scope: "#/properties/spread.min",
                },
                {
                  type: "Control",
                  scope: "#/properties/spread.max",
                },
                {
                  type: "Control",
                  scope: "#/properties/skewInitialQuote",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.exchange",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.feed",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.symbol",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/quoteLevels",
            },
          ],
        },
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Group",
              label: "Risk",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/alpha",
                },
                {
                  type: "Control",
                  scope: "#/properties/biasFactor",
                },
                {
                  type: "Control",
                  scope: "#/properties/maxLoss",
                },
                {
                  type: "Control",
                  scope: "#/properties/skewTakeProfit",
                },
                {
                  type: "Control",
                  scope: "#/properties/maxTimeOpen",
                },
                {
                  type: "Control",
                  scope: "#/properties/inventory.max",
                },
                {
                  type: "Control",
                  scope: "#/properties/inventory.skew",
                },
                {
                  type: "Control",
                  scope: "#/properties/rsiRange",
                },
              ],
            },
            {
              type: "Group",
              label: "Sizing",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/sizing.min",
                },
                {
                  type: "Control",
                  scope: "#/properties/sizing.max",
                },
                {
                  type: "Control",
                  scope: "#/properties/sizing.orderLimit",
                },
                {
                  type: "Control",
                  scope: "#/properties/maxUntouchedPositions",
                },
                {
                  type: "Control",
                  scope: "#/properties/untouchedThreshold",
                },
              ],
            },
            {
              type: "Group",
              label: "Quote Settings",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/gracePeriod",
                },
                {
                  type: "Control",
                  scope: "#/properties/decayTimeout",
                },
                {
                  type: "Control",
                  scope: "#/properties/updateFrequency",
                },
                {
                  type: "Control",
                  scope: "#/properties/maxRestingDistance",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/markets",
    },
  ],
};
