import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  firebaseAuth,
  useSignInWithEmailAndPassword,
  useAuthState,
} from "../firebase";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import Loader from "src/components/Loader";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const [signIn] = useSignInWithEmailAndPassword(firebaseAuth);
  const [user, signInLoading] = useAuthState(firebaseAuth);

  useEffect(() => {
    if (user && !signInLoading) {
      navigate("/");
    }
  }, [user, signInLoading]);

  const handleLogin = async () => {
    try {
      const response = await signIn(email, password);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <section className="bg-gray-800">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-white"
          >
            <Logo size={72} className="mb-2" />
          </a>
          <div className="rounded-sm shadow border bg-gray-900 border-black w-96">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-medium leading-tight tracking-tight md:text-2xl text-white">
                Sign in to your account
              </h1>
              {!signInLoading && (
                <form className="space-y-4 md:space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="border text-sm rounded-sm focus:ring-primary block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                      placeholder="name@quantfi.tech"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="border text-sm rounded-sm focus:ring-primary block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {/* <div className="flex items-center justify-between">
                  <a
                    href="#"
                    className="text-sm font-medium hover:underline text-white"
                  >
                    Forgot password?
                  </a>
                </div> */}
                  <button
                    type="button"
                    className="w-full text-white bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
                    onClick={handleLogin}
                  >
                    Sign in
                  </button>
                </form>
              )}
              {signInLoading && (
                <div>
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
