import { createTheme, TableStyles } from "react-data-table-component";
import moment from "moment";
import { TableColumn } from "react-data-table-component";
import Flash from "../components/Flash";

export function setTableTheme() {
  createTheme(
    "qf",
    {
      text: {
        // primary: "#268bd2",
        // secondary: "#2aa198",
      },
      background: {
        default: "transparent",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#000",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );
}

export function format(formatter: Intl.NumberFormat, field: string) {
  return (row) => {
    if (!row[field]) {
      return "-";
    }
    return formatter.format(row[field]);
  };
}

export function shortId(field) {
  return (row) => {
    return row[field].split("-")[0];
  };
}

export function zeroAsDash(field) {
  return {
    when: (row) => row[field] == 0,
    classNames: ["text-gray-500"],
  };
}

export function sideColors(field) {
  return [
    {
      when: (row) => row[field] == "ENTRY",
      classNames: ["text-green-400"],
    },
    {
      when: (row) => row[field] == "EXIT",
      classNames: ["text-red-400"],
    },
  ];
}

export function date(field) {
  return (row) => {
    return moment(row[field]).format("YYYY-MM-DD HH:mm:ss.SSS");
  };
}

export function updateOnFlash<T>(
  row: T,
  index: number,
  column: TableColumn<T>,
  id: string
) {
  const value = column.selector(row, index) as number;
  return (
    <Flash
      upClassName="text-green-500"
      downClassName="text-red-500"
      value={value}
    >
      <span className="whitespace-nowrap">
        {column.format ? column.format(row, index) : value}
      </span>
    </Flash>
  );
}

export const customStyles: TableStyles = {
  headCells: {
    style: {
      opacity: 0.7,
      // textTransform: "uppercase",
      "&:hover": {
        opacity: 1,
      },
    },
  },
  head: {
    style: {
      fontWeight: 400,
    },
  },
};
