import RequestForQuotes from "../sections/RequestForQuotes";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { Helmet } from "react-helmet";

export default function OrderRouter() {
  return (
    <>
      <Helmet>
        <title>Order Router</title>
      </Helmet>
      <div className="grid gap-px w-full">
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>RFQs</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <RequestForQuotes />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
