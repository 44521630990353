import Logo from "./Logo";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import {
  LiaBriefcaseSolid,
  LiaExchangeAltSolid,
  LiaInfinitySolid,
  LiaTagSolid,
  LiaVectorSquareSolid,
  LiaHockeyPuckSolid,
} from "react-icons/lia";
import { firebaseAuth, useAuthState, useSignOut } from "../firebase";

export const Navbar = () => {
  const [user] = useAuthState(firebaseAuth);
  const [signOut] = useSignOut(firebaseAuth);
  const primaryMenuItems = [
    {
      name: "Trading",
      link: "/trading",
      icon: <LiaExchangeAltSolid />,
    },
    {
      name: "Treasury & Risk",
      link: "/treasury-risk",
      icon: <LiaBriefcaseSolid />,
    },
    {
      name: "Credit",
      link: "/credit",
      icon: <LiaTagSolid />,
    },
    {
      name: "Settlements",
      link: "/settlements",
      icon: <LiaTagSolid />,
    },
    {
      name: "Strategy",
      link: "/strategy",
      icon: <LiaInfinitySolid />,
    },
    {
      name: "Order Router",
      link: "/order-router",
      icon: <LiaVectorSquareSolid />,
    },
    {
      name: "Positions",
      link: "/positions",
      icon: <LiaHockeyPuckSolid />,
    },
  ];
  return (
    <div className="flex">
      <div className="w-52 h-screen overscroll-auto flex p-3 bg-gray-800 border-r border-black flex flex-1 flex-col justify-between sticky">
        <div className="space-y-3">
          <div className="flex items-center justify-between px-2 py-2">
            <Logo size={44} />
            <h2 className="text-xs font-bold text-white"></h2>
          </div>
          <div className="flex-1 space-y-2 grid">
            {primaryMenuItems.map(({ name, link, icon }) => (
              <NavLink
                key={name}
                className={({ isActive, isPending }) =>
                  clsx(
                    "text-gray-100 font-normal rounded-sm py-2 px-2 cursor-pointer hover:opacity-100 hover:bg-gray-700 flex items-center gap-2 opacity-50",
                    { "bg-gray-700 !opacity-100": isActive }
                  )
                }
                to={link}
              >
                {icon}
                {name}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="py-2 px-2 space-y-1">
          <div className="flex items-center justify-between">
            <div className="text-sm font-medium text-white">
              {user?.displayName || user?.email}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <a
              className="text-gray-100 font-normal cursor-pointer hover:bg-gray-700 hover:underline"
              onClick={() => {
                signOut();
              }}
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
