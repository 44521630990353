export default {
  usd: new Intl.NumberFormat("en-US", {
    style: "decimal",
  }),
  noDecimals: new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
  }),
  decimal: new Intl.NumberFormat("en-US", {
    style: "decimal",
  }),
  balanceDecimals: new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  }),
  percent: new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumSignificantDigits: 2,
  }),
  signedPercent: new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumSignificantDigits: 2,
    signDisplay: "always",
  }),
  int: new Intl.NumberFormat("en-US"),
};
