import useSWR from "swr";
import { useMemo } from "react";
// utils
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
// types
import { IOrgBalances, ISettlements, IOrgNames } from "src/types/settlements";

// ----------------------------------------------------------------------

export function useGetOrganizationBalances() {
  const URL = endpoints.settlements.orgbalances;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });
  const memoizedValue = useMemo(
    () => ({
      orgbalances: (data as IOrgBalances[]) || [],
      orgbalancesLoading: isLoading,
      orgbalancesError: error,
      orgbalancesValidating: isValidating,
      orgbalancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}

export function useGetOrganizations() {
  const URL = endpoints.settlements.orgs;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });

  const memoizedValue = useMemo(
    () => ({
      orgnames: (data as IOrgNames[]) || [],
      orgnamesLoading: isLoading,
      orgnamesError: error,
      orgnamesValidating: isValidating,
      orgnamesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}

export function useGetSettlements() {
  const URL = endpoints.settlements.list;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });
  const memoizedValue = useMemo(
    () => ({
      settlements: (data as ISettlements[]) || [],
      settlementsLoading: isLoading,
      settlementsError: error,
      settlementsValidating: isValidating,
      settlementsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
