import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles } from "../utils/table";

function EmptyCell() {
  return <div className="bg-gray-600 animate-pulse h-2 w-full"></div>;
}

export default function EmptyTable({ columns }) {
  return (
    <div className="w-full">
      <DataTable
        theme="qf"
        columns={columns.map((column: TableColumn<any>) => ({
          name: column.name,
          cell: EmptyCell,
        }))}
        data={Array(10).fill({})}
        responsive
        pagination
        dense
        fixedHeader
        customStyles={customStyles}
      />
    </div>
  );
}
