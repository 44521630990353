export const smartOrderRouter = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Group",
          label: "Smart Order Router",
          elements: [
            {
              type: "Control",
              scope: "#/properties/pricingStrategy",
            },
            {
              type: "Control",
              scope: "#/properties/riskPremium",
            },
            {
              type: "Control",
              scope: "#/properties/minSpread",
            },
            {
              type: "Control",
              scope: "#/properties/quoteExpirationTime",
            },
            {
              type: "Control",
              scope: "#/properties/spreadCoefficients.alpha",
              label: "Spread α",
            },
            {
              type: "Control",
              scope: "#/properties/spreadCoefficients.beta",
              label: "Spread β",
            },
            {
              type: "Control",
              scope: "#/properties/rsiPremium",
            },
            {
              type: "Control",
              scope: "#/properties/orderSizePremium",
            },
          ],
        },
        {
          type: "Group",
          label: "Symbol Information",
          elements: [
            {
              type: "Control",
              scope: "#/properties/externalInfo.symbol",
              label: "Symbol",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.baseAsset",
              label: "Base Asset",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.baseAssetPrecision",
              label: "Base Asset Precision",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.quoteAsset",
              label: "Quote Asset",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.quoteAssetPrecision",
              label: "Quote Asset Precision",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.minOrderSize",
              label: "Min Order Size",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.maxOrderSize",
              label: "Max Order Size",
            },
            {
              type: "Control",
              scope: "#/properties/externalInfo.maxOrderValue",
              label: "Max Order Value",
            },
          ],
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Group",
              label: "Reference",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/reference.exchange",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.feed",
                },
                {
                  type: "Control",
                  scope: "#/properties/reference.symbol",
                },
              ],
            },
          ],
        },
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Group",
              label: "General Settings",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/asset",
                },
                {
                  type: "Control",
                  scope: "#/properties/updateFrequency",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/markets",
    },
  ],
};
