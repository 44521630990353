import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create({
  baseURL: process.env["REACT_APP_API_URL"],
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

export const REFRESH_INTERVAL = 1000;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: "/api/auth/me",
    login: "/api/auth/login",
  },
  instances: {
    list: "/instances",
    parameters: "/instances/parameters",
    signals: "/instances/signals",
  },
  positions: {
    open: "/positions/open",
    closed: "/positions/closed",
    futures: "/positions/futures",
    fundingFees: "/positions/funding-fees",
  },
  orders: {
    open: "/orders/open",
    closed: "/orders/closed",
    rejected: "/orders/rejected",
  },
  requestForQuotes: {
    list: "/request-for-quotes",
  },
  balances: {
    list: "/balances",
    tranfers: "/balances/transfers",
  },
  keyMetrics: {
    list: "/key-metrics",
  },
  settlements: {
    list: "/settlements",
    orgbalances: "/settlements/org-balances",
    orgs: "/settlements/orgs",
  },
};
