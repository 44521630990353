import useSWR from "swr";
import { useMemo } from "react";
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
import { IKeyMetric } from "src/types/key-metric";

export function useGetKeyMetrics() {
  const { data, isLoading, error, isValidating } = useSWR(
    endpoints.keyMetrics.list,
    fetcher,
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  );
  const memoizedValue = useMemo(
    () => ({
      keyMetrics: (data as IKeyMetric[]) || [],
      keyMetricsLoading: isLoading,
      keyMetricsError: error,
      keyMetricsValidating: isValidating,
      keyMetricsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
