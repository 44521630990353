import { memo, forwardRef } from "react";
import { CustomContentProps, SnackbarContent } from "notistack";
import clsx from "clsx";

const ThemeResponsiveSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  function ThemeResponsiveSnackbar(props, forwardedRef) {
    const { message, variant } = props;
    const classes = {};
    return (
      <SnackbarContent
        ref={forwardedRef}
        className={clsx(
          "shadow-md bg-gray-900 py-4 px-6 rounded-sm",
          classes[variant]
        )}
      >
        <div className="">{message}</div>
      </SnackbarContent>
    );
  }
);

export default memo(ThemeResponsiveSnackbar);
