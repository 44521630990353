import useSWR from "swr";
import { useMemo } from "react";
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
import {
  IInstance,
  IInstanceParameters,
  IInstanceSignals,
} from "src/types/instance";

export function useGetInstances() {
  const { data, isLoading, error, isValidating } = useSWR(
    endpoints.instances.list,
    fetcher
  );
  const memoizedValue = useMemo(
    () => ({
      instances: (data as IInstance[]) || [],
      instancesLoading: isLoading,
      instancesError: error,
      instancesValidating: isValidating,
      instancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetInstanceParameters() {
  const { data, isLoading, error, isValidating } = useSWR(
    endpoints.instances.parameters,
    fetcher
  );
  const memoizedValue = useMemo(
    () => ({
      instances: (data as IInstanceParameters[]) || [],
      instancesLoading: isLoading,
      instancesError: error,
      instancesValidating: isValidating,
      instancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetInstanceSignals() {
  const { data, isLoading, error, isValidating } = useSWR(
    endpoints.instances.signals,
    fetcher,
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  );
  const memoizedValue = useMemo(
    () => ({
      instances: (data as IInstanceSignals[]) || [],
      instancesLoading: isLoading,
      instancesError: error,
      instancesValidating: isValidating,
      instancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
