import { useGetSettlements } from "../api/settlements";
import DataTable, { TableColumn } from "react-data-table-component";
import Loader from "../components/Loader";
import { ISettlements } from "src/types/settlements";
import { format, date, customStyles } from "../utils/table";
import formatter from "../utils/format";

import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<ISettlements>[] = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Asset",
    selector: (row) => row.Asset,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.Amount,
    sortable: true,
    format: format(formatter.decimal, "Amount"),
  },
  {
    name: "Created Date",
    selector: (row) => row.createdAt,
    sortable: true,
    format: date("createdAt"),
  },
  {
    name: "Updated Date",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
  },
];

export default function Settlements() {
  const { settlements, settlementsLoading } = useGetSettlements();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={settlements}
      responsive
      pagination
      dense
      progressPending={settlementsLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      fixedHeaderScrollHeight="100%"
      customStyles={customStyles}
    />
  );
}
