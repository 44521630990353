import { useGetFundingFees, useGetFuturePositions } from "../api/positions";
import DataTable, { TableColumn } from "react-data-table-component";
import Loader from "../components/Loader";
import formatter from "../utils/format";
import { IFundingFee, IFuturePosition } from "src/types/position";
import {
  format,
  zeroAsDash,
  shortId,
  date,
  customStyles,
  updateOnFlash,
} from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IFundingFee>[] = [
  // {
  //   name: "ID",
  //   selector: (row) => row.id,
  //   sortable: true,
  //   // format: shortId("id"),
  // },
  {
    name: "Exchange",
    selector: (row) => row.exchange,
    sortable: true,
  },
  {
    name: "Asset",
    selector: (row) => row.symbol,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    format: format(formatter.decimal, "amount"),
    conditionalCellStyles: [zeroAsDash("amount")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Currency",
    selector: (row) => row.currency,
    sortable: true,
  },
  {
    name: "Last Updated",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
    cell: updateOnFlash,
  },
];

export default function FundingFees() {
  const { fundingFees, fundingFeesLoading } = useGetFundingFees();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={fundingFees}
      responsive
      pagination
      dense
      progressPending={fundingFeesLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
}
