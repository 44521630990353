import { useGetRequestForQuotes } from "../api/request-for-quote";
import DataTable, { TableColumn } from "react-data-table-component";
import { IRequestForQuote } from "src/types/request-for-quote";
import { shortId, date, customStyles, zeroAsDash } from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IRequestForQuote>[] = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    format: shortId("id"),
  },
  {
    name: "Asset",
    selector: (row) => row.asset,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.quantity,
    sortable: true,
  },
  {
    name: "Price",
    selector: (row) => row.price,
    sortable: true,
  },
  {
    name: "Fee",
    selector: (row) => row.fee,
    conditionalCellStyles: [zeroAsDash("fee")],
    sortable: true,
  },
  {
    name: "Organization",
    selector: (row) => row.organizationName,
    sortable: true,
  },
  {
    name: "Last Updated",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
  },
];

export default function RequestForQuotes() {
  const { requestForQuotes, requestForQuotesLoading } =
    useGetRequestForQuotes();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={requestForQuotes}
      responsive
      pagination
      dense
      progressPending={requestForQuotesLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      fixedHeaderScrollHeight="100%"
      customStyles={customStyles}
    />
  );
}
