import { useGetBalances } from "../api/balances";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import formatter from "../utils/format";
import { IBalance } from "src/types/balance";
import { format, customStyles, date, updateOnFlash } from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IBalance>[] = [
  {
    name: "Exchange",
    selector: (row) => row.exchange,
    sortable: true,
  },
  {
    name: "Asset",
    selector: (row) => row.asset,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.balance,
    sortable: true,
    format: format(formatter.noDecimals, "balance"),
    cell: updateOnFlash,
  },
  {
    name: "USDT Value",
    selector: (row) => row.value,
    sortable: true,
    format: format(formatter.noDecimals, "value"),
    cell: updateOnFlash,
  },
  {
    name: "Imbalance",
    selector: (row) => row.imbalancePercentage,
    sortable: true,
    format: format(formatter.signedPercent, "imbalancePercentage"),
    cell: updateOnFlash,
  },
  {
    name: "Target",
    selector: (row) => row.targetPercentage,
    sortable: true,
    format: format(formatter.signedPercent, "targetPercentage"),
    cell: updateOnFlash,
  },
  {
    name: "Last Update",
    selector: (row) => row.createdAt,
    sortable: true,
    format: date("createdAt"),
    cell: updateOnFlash,
  },
];

const ExpandedComponent: React.FC<ExpanderComponentProps<IBalance>> = ({
  data,
}) => {
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

export default function Instances() {
  const { balances, balancesLoading } = useGetBalances();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={balances}
      responsive
      pagination
      dense
      progressPending={balancesLoading}
      // expandableRows
      expandableRowsComponent={ExpandedComponent}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      // highlightOnHover
      customStyles={customStyles}
    />
  );
}
