import OrgBalances from "../sections/OrgBalances";
import Settlements from "../sections/Settlements";
import AddSettlements from "../sections/AddSettlements";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { Helmet } from "react-helmet";

export default function SettlementsPage() {
  return (
    <>
      <Helmet>
        <title>Settlements</title>
      </Helmet>
      <div className="grid gap-px w-full">
        <Group key="add-settlements">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Record new Settlement</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <AddSettlements />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="settlements">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Past Settlements</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <Settlements />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
