import useSWR from "swr";
import { useMemo } from "react";
// utils
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
// types
import { IFundingFee, IFuturePosition, IPosition } from "src/types/position";

// ----------------------------------------------------------------------

export function useGetOpenPositions() {
  const URL = endpoints.positions.open;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });

  const memoizedValue = useMemo(
    () => ({
      positions: (data as IPosition[]) || [],
      positionsLoading: isLoading,
      positionsError: error,
      positionsValidating: isValidating,
      positionsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetClosedPositions() {
  const URL = endpoints.positions.closed;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      positions: (data as IPosition[]) || [],
      positionsLoading: isLoading,
      positionsError: error,
      positionsValidating: isValidating,
      positionsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetFuturePositions() {
  const URL = endpoints.positions.futures;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      positions: (data as IFuturePosition[]) || [],
      positionsLoading: isLoading,
      positionsError: error,
      positionsValidating: isValidating,
      positionsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetFundingFees() {
  const URL = endpoints.positions.fundingFees;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      fundingFees: (data as IFundingFee[]) || [],
      fundingFeesLoading: isLoading,
      fundingFeesError: error,
      fundingFeesValidating: isValidating,
      fundingFeesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
