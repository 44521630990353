import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyALXRAURS0QfW-zXeSbcRqZZkwExlfok8A",
  authDomain: "localhost",
  projectId: "blue-356113",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

export {
  useAuthState,
  useSignOut,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
