export const trifecta = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Group",
                  label: "General Settings",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/asset",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/updateFrequency",
                    },
                  ],
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Group",
                  label: "Spot",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/spot.reference.exchange",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/spot.reference.feed",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/spot.reference.symbol",
                    },
                  ],
                },
                {
                  type: "Group",
                  label: "Swap",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/swap.reference.exchange",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/swap.reference.feed",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/swap.reference.symbol",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Group",
      label: "Smart Order Router",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Group",
                  label: "Quote Pricing",
                  elements: [
                    // {
                    //   type: "Control",
                    //   scope: "#/properties/pricingStrategy",
                    // },
                    {
                      type: "Control",
                      scope: "#/properties/riskPremium",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/minSpread",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/quoteExpirationTime",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/spreadCoefficients.alpha",
                      label: "Spread α",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/spreadCoefficients.beta",
                      label: "Spread β",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/rsiPremium",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/orderSizePremium",
                    },
                  ],
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Group",
                  label: "Symbol Information",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.symbol",
                      label: "Symbol",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.baseAsset",
                      label: "Base Asset",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.baseAssetPrecision",
                      label: "Base Asset Precision",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.quoteAsset",
                      label: "Quote Asset",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.quoteAssetPrecision",
                      label: "Quote Asset Precision",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.minOrderSize",
                      label: "Min Order Size",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.maxOrderSize",
                      label: "Max Order Size",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/externalInfo.maxOrderValue",
                      label: "Max Order Value",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Group",
      label: "Market Making",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Group",
                  label: "Spread",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/gammaOne",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/gammaTwo",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/spread.min",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/spread.max",
                    },
                  ],
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Group",
                  label: "Inventory",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/inventory.max",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/inventory.spot.skew",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/inventory.swap.skew",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/minPremium",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/maxPremium",
                    },
                  ],
                },
                {
                  type: "Group",
                  label: "Sizing",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/sizing.min",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/sizing.max",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/sizing.orderLimit",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/markets",
    },
  ],
};
