import React, { useState } from "react";
import { useGetOrganizations } from "../api/settlements";
import axios from "../utils/axios";

interface FormErrors {
  assetName?: string;
  assetAmount?: string;
}

function AddSettlements() {
  const [assetName, setAssetName] = useState("");
  const [assetAmount, setAssetAmount] = useState(0);
  const [action, setAction] = useState("receive");
  const [orgId, setOrgId] = useState("");
  const [errors, setErrors] = useState<FormErrors>({});
  const { orgnames, orgnamesLoading } = useGetOrganizations();

  const handleAssetAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "") {
      setAssetAmount(0);
      setErrors({ ...errors, assetAmount: "Asset Amount is required" });
    } else {
      const number = Number(value);
      if (isNaN(number) || number <= 0) {
        setErrors({
          ...errors,
          assetAmount: "Asset Amount must be a positive number",
        });
      } else {
        setErrors({ ...errors, assetAmount: undefined });
      }
      setAssetAmount(number);
    }
  };

  const handleSubmit = () => {
    console.log({ assetName, assetAmount, action, orgId });
    axios
      .post(`/settlements/record`, {
        assetName,
        assetAmount,
        action,
        orgId,
      })
      .then((response) => {
        if (response.status === 200) {
          window.alert("Record added successfully!");
          setAssetName("");
          setAssetAmount(0);
          setAction("receive");
          setOrgId("");
        } else {
          window.alert("Failed to add the record.");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setAssetName("");
        setAssetAmount(0);
        setAction("receive");
        setOrgId("");
        window.alert("Error: Could not add the record.");
      });
  };

  return (
    <div>
      <div className="space-x-4 flex">
        <select
          className="py-2 px-4 text-white bg-gray-600"
          value={orgId}
          onChange={(e) => setOrgId(e.target.value)}
        >
          <option value="" disabled selected>
            Select an Organization
          </option>
          {orgnamesLoading ? (
            <option disabled>Loading...</option>
          ) : (
            orgnames.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))
          )}
        </select>
        <input
          type="text"
          className="py-2 px-4 text-black"
          placeholder="Enter Asset Name"
          value={assetName}
          onChange={(e) => setAssetName(e.target.value)}
        />
        <div className="text-red-500 text-xs">{errors.assetName}</div>

        <input
          type="number"
          className="py-2 px-4 text-black"
          placeholder="Enter Asset Amount"
          value={assetAmount || ""}
          onChange={handleAssetAmountChange}
          min="0"
        />
        <div className="text-red-500 text-xs">{errors.assetAmount}</div>

        <select
          className="py-2 px-4 text-white bg-primary-500"
          value={action}
          onChange={(e) => setAction(e.target.value)}
        >
          <option value="send">Send</option>
          <option value="receive">Receive</option>
        </select>

        <button
          className="bg-primary-500 hover:bg-primary-600 text-white font-medium py-2 px-4"
          onClick={handleSubmit}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default AddSettlements;
