import { useEffect, useRef, useState, PropsWithChildren } from "react";
import clsx from "clsx";

export enum FlashDirection {
  Down,
  Up,
  Even,
}

export interface Props {
  flashClassName?: string;
  evenClassName?: string;
  downClassName?: string;
  upClassName?: string;
  value: number;
}

export default function Flash({
  flashClassName = "",
  downClassName = "",
  upClassName = "",
  value,
  children,
}: PropsWithChildren<Props>) {
  const ref = useRef<number>(value);
  const [flash, setFlash] = useState<FlashDirection | null>(null);
  const style = {
    transition: `background-color 200ms ease-in-out`,
  };
  const cls = clsx({
    [flashClassName]: flash == FlashDirection.Even,
    [downClassName]: flash == FlashDirection.Down,
    [upClassName]: flash == FlashDirection.Up,
  });

  useEffect(() => {
    if (!value || !ref.current) {
      ref.current = value;
      return;
    }

    if (ref.current === value) {
      setFlash(null);
      return;
    }

    if (value > ref.current) {
      setFlash(FlashDirection.Up);
    } else if (value < ref.current) {
      setFlash(FlashDirection.Down);
    } else if (value != ref.current) {
      setFlash(FlashDirection.Even);
    }

    const timeoutInterval = setTimeout(() => {
      setFlash(null);
    }, 200);

    ref.current = value;

    return () => {
      clearTimeout(timeoutInterval);
    };
  }, [value]);

  return (
    <div className={cls} style={style}>
      {children}
    </div>
  );
}
