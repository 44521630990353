import {
  vanillaCells,
  vanillaRenderers,
  JsonFormsStyleContext,
  vanillaStyles,
} from "@jsonforms/vanilla-renderers";

export const styleContextValue = {
  styles: [
    ...vanillaStyles,
    {
      name: "control",
      classNames: ["flex"],
    },
    {
      name: "control.input",
      classNames: [
        "text-white",
        "py-1",
        "px-2",
        "bg-gray-900",
        "flex-1",
        "border",
        "border-gray-900",
        "focus:border-gray-700",
        "focus:outline-none",
        "w-full",
      ],
    },
    {
      name: "control.label",
      classNames: ["px-2", "flex-1"],
    },
    {
      name: "horizontal.layout",
      classNames: ["grid", "grid-flow-col", "space-y-1", "gap-4"],
    },
    {
      name: "vertical.layout",
      classNames: ["space-y-2"],
    },
    {
      name: "group.label",
      classNames: [
        "px-2",
        "flex-1",
        "mb-2",
        "uppercase",
        "text-gray-500",
        "font-medium",
      ],
    },
    {
      name: "group.layout",
      classNames: ["space-y-1"],
    },
    {
      name: "array.table",
      classNames: ["w-full"],
    },
    {
      name: "array.table.table",
      classNames: ["w-full"],
    },
    {
      name: "array.table.head",
      classNames: [
        "flex-1",
        "uppercase",
        "text-gray-500",
        "font-medium",
        "text-left",
      ],
    },
    {
      name: "array.table.body.cell",
      classNames: ["px-2"],
    },
    {
      name: "array.table.head.cell",
      classNames: ["px-2", "py-2", "font-medium"],
    },
    {
      name: "array.table.label",
      classNames: [
        "flex-1",
        "uppercase",
        "text-gray-500",
        "font-medium",
        "text-left",
        "px-2",
        "py-2",
      ],
    },
    {
      name: "array.table.button",
      classNames: ["flex-1"],
    },
  ],
};
