export default function Metric({ name, value, formatter, progressPending }) {
  return (
    <div className="bg-gray-800 py-4 px-4">
      <div className="uppercase text-gray-400 font-medium mb-1">{name}</div>
      {!progressPending && (
        <div className="text-lg">{formatter.format(value)}</div>
      )}
      {progressPending && (
        <div className="text-lg bg-gray-600 animate-pulse h-2 w-1/3 w-full mt-3 text-transparent"></div>
      )}
    </div>
  );
}
