import OpenPositions from "../sections/OpenPositions";
import Orders from "../sections/Orders";
import ClosedPositions from "../sections/ClosedPositions";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { OrdersQuery } from "src/api/orders";
import { Helmet } from "react-helmet";

export default function Trading() {
  return (
    <>
      <Helmet>
        <title>Trading</title>
      </Helmet>
      <div className="grid gap-px w-full">
        <Group key="instances">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Open Orders</Tab>
              <Tab className={tabsTheme.tab}>Filled Orders</Tab>
              <Tab className={tabsTheme.tab}>Rejected Orders</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <Orders type={OrdersQuery.Open} />
              </Tab.Panel>
              <Tab.Panel className={tabsTheme.panel}>
                <Orders type={OrdersQuery.Filled} />
              </Tab.Panel>
              <Tab.Panel className={tabsTheme.panel}>
                <Orders type={OrdersQuery.Rejected} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Open Positions</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <OpenPositions />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="positions">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Closed Positions</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <ClosedPositions />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
