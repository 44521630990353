import { useGetInstances } from "../api/instances";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import formatter from "../utils/format";
import { IInstance } from "src/types/instance";
import { format, customStyles, updateOnFlash } from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IInstance>[] = [
  // {
  //   name: "Strategy",
  //   selector: (row) => row.strategy,
  //   sortable: true,
  // },
  {
    name: "Asset",
    selector: (row) => row.asset,
    sortable: true,
  },
  {
    name: "State",
    selector: (row) => row.state,
    sortable: true,
  },
  {
    name: "Inventory (#)",
    selector: (row) => row.inventory,
    sortable: true,
    format: format(formatter.noDecimals, "inventory"),
    cell: updateOnFlash,
  },
  {
    name: "Outstanding Buy USD ($)",
    selector: (row) => row.outstandingBuy,
    sortable: true,
    format: format(formatter.noDecimals, "outstandingBuy"),
    cell: updateOnFlash,
  },
  {
    name: "Outstanding Sell ($)",
    selector: (row) => row.outstandingSell,
    sortable: true,
    format: format(formatter.noDecimals, "outstandingSell"),
    cell: updateOnFlash,
  },
  {
    name: "Profit ($)",
    selector: (row) => row.profit,
    sortable: true,
    cell: updateOnFlash,
  },
  {
    name: "Fee In ($)",
    selector: (row) => row.feeIn,
    sortable: true,
    format: format(formatter.decimal, "feeIn"),
    cell: updateOnFlash,
  },
  {
    name: "Fee Out ($)",
    selector: (row) => row.feeOut,
    sortable: true,
    format: format(formatter.decimal, "feeOut"),
    cell: updateOnFlash,
  },
];

const ExpandedComponent: React.FC<ExpanderComponentProps<IInstance>> = ({
  data,
}) => {
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

export default function Instances() {
  const { instances, instancesLoading } = useGetInstances();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={instances}
      responsive
      pagination
      dense
      progressPending={instancesLoading}
      // expandableRows
      expandableRowsComponent={ExpandedComponent}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
}
