import { Login } from "./pages/Login";
import { PublicLayout } from "./layouts/PublicLayout";
import { PlatformLayout } from "./layouts/PlatformLayout";
import Dashboard from "./pages/Dashboard";
import Treasury from "./pages/Treasury";
import Trading from "./pages/Trading";
import Credit from "./pages/Credit";
import Settlements from "./pages/Settlements";
import Strategy from "./pages/Strategy";
import OrderRouter from "./pages/OrderRouter";
import Positions from "./pages/Positions";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { setTableTheme } from "./utils/table";
import { JsonFormsStyleContext } from "@jsonforms/vanilla-renderers";
import { styleContextValue } from "./utils/form";
import { SnackbarProvider } from "notistack";
import ThemeResponsiveSnackbar from "./components/Snackbar";

setTableTheme();

const router = createBrowserRouter([
  {
    path: "/",
    element: <PlatformLayout item={<Dashboard />} />,
  },
  {
    path: "/login",
    element: <PublicLayout item={<Login />} />,
  },
  {
    path: "/treasury-risk",
    element: <PlatformLayout item={<Treasury />} />,
  },
  {
    path: "/trading",
    element: <PlatformLayout item={<Trading />} />,
  },
  {
    path: "/credit",
    element: <PlatformLayout item={<Credit />} />,
  },
  {
    path: "/settlements",
    element: <PlatformLayout item={<Settlements />} />,
  },
  {
    path: "/strategy",
    element: <PlatformLayout item={<Strategy />} />,
  },
  {
    path: "/order-router",
    element: <PlatformLayout item={<OrderRouter />} />,
  },
  {
    path: "/positions",
    element: <PlatformLayout item={<Positions />} />,
  },
]);

function App() {
  return (
    <div className="text-white text-xs">
      <JsonFormsStyleContext.Provider value={styleContextValue}>
        <RouterProvider router={router} />
      </JsonFormsStyleContext.Provider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        // autoHideDuration={1000}
        dense
        Components={{
          default: ThemeResponsiveSnackbar,
          success: ThemeResponsiveSnackbar,
          error: ThemeResponsiveSnackbar,
          warning: ThemeResponsiveSnackbar,
        }}
        preventDuplicate
      />
    </div>
  );
}

export default App;
