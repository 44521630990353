import { useGetOrders, OrdersQuery } from "../api/orders";
import DataTable, { TableColumn } from "react-data-table-component";
import { IOrder } from "src/types/order";
import formatter from "../utils/format";
import {
  format,
  zeroAsDash,
  sideColors,
  shortId,
  date,
  customStyles,
  updateOnFlash,
} from "../utils/table";
import EmptyTable from "src/components/EmptyTable";

const columns: TableColumn<IOrder>[] = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    format: shortId("id"),
  },
  {
    name: "Exchange",
    selector: (row) => row.exchange,
    sortable: true,
  },
  {
    name: "Symbol",
    selector: (row) => row.symbol,
    sortable: true,
  },
  {
    name: "Side",
    selector: (row) => row.side,
    format: (row) => {
      if (row["side"] === "ENTRY") {
        return "BUY";
      }
      if (row["side"] === "EXIT") {
        return "SELL";
      }
    },
    conditionalCellStyles: [...sideColors("side")],
    sortable: true,
  },
  {
    name: "Order Type",
    selector: (row) => row.orderType,
    sortable: true,
  },
  {
    name: "Order Kind",
    selector: (row) => row.orderKind,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.units,
    sortable: true,
    format: format(formatter.decimal, "units"),
    conditionalCellStyles: [zeroAsDash("units")],
    right: true,
  },
  {
    name: "Filled",
    selector: (row) => row.filled,
    sortable: true,
    format: format(formatter.decimal, "filled"),
    conditionalCellStyles: [zeroAsDash("filled")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Price",
    selector: (row) => row.price,
    sortable: true,
    format: format(formatter.decimal, "price"),
    conditionalCellStyles: [zeroAsDash("price")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Fee",
    selector: (row) => row.fee,
    sortable: true,
    format: format(formatter.decimal, "fee"),
    conditionalCellStyles: [zeroAsDash("fee")],
    right: true,
    cell: updateOnFlash,
  },
  {
    name: "Fee Currency",
    selector: (row) => row.feeCurrency,
    sortable: true,
    conditionalCellStyles: [zeroAsDash("feeCurrency")],
    right: true,
  },
  {
    name: "State",
    selector: (row) => row.state,
    sortable: true,
    cell: updateOnFlash,
  },
  {
    name: "Reason",
    selector: (row) => row.rejectionReason || row.cancelReason || "-",
    sortable: true,
  },
  {
    name: "Last Updated",
    selector: (row) => row.updatedAt,
    sortable: true,
    format: date("updatedAt"),
  },
];

export default function Orders({ type }: { type: OrdersQuery }) {
  const { orders, ordersLoading } = useGetOrders(type);
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={orders}
      responsive
      pagination
      dense
      progressPending={ordersLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      customStyles={customStyles}
    />
  );
}
