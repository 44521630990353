import InstanceParameters from "../sections/InstanceParameters";
import InstanceSignals from "../sections/InstanceSignals";
import Group from "../components/Group";
import { Tab } from "@headlessui/react";
import { tabsTheme } from "../components/Tabs";
import { Helmet } from "react-helmet";

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Quant</title>
      </Helmet>
      <div className="grid gap-px w-full">
        <Group key="parameters">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Parameters</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <InstanceParameters />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
        <Group key="signals">
          <Tab.Group>
            <Tab.List className={tabsTheme.list}>
              <Tab className={tabsTheme.tab}>Signals</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className={tabsTheme.panel}>
                <InstanceSignals />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Group>
      </div>
    </>
  );
}
