import { useGetKeyMetrics } from "../api/key-metrics";
import Loader from "../components/Loader";
import Metric from "../components/Metric";
import format from "../utils/format";

const columns = [
  {
    name: "Inventory",
    key: "inventory",
    formatter: format.noDecimals,
  },
  {
    name: "Open Positions",
    key: "openPositions",
    formatter: format.int,
  },
  {
    name: "Profit",
    key: "profit",
    formatter: format.usd,
  },
  // {
  //   name: "Balance",
  //   key: "balance",
  //   formatter: format.noDecimals,
  // },
  {
    name: "Fee In",
    key: "feeIn",
    formatter: format.usd,
  },
  {
    name: "Fee Out",
    key: "feeOut",
    formatter: format.usd,
  },
];

export default function KeyMetrics() {
  const { keyMetrics, keyMetricsLoading } = useGetKeyMetrics();
  return (
    <div className="grid grid-cols-6 gap-px">
      {columns.map((column) => {
        const keyMetric = keyMetrics.find(
          ({ variable }) => variable === column.key
        );
        return (
          <Metric
            key={keyMetric?.variable}
            name={column.name}
            value={keyMetric?.value}
            formatter={column.formatter}
            progressPending={keyMetricsLoading}
          />
        );
      })}
    </div>
  );
}
