import { useGetOrganizationBalances } from "../api/settlements";
import DataTable, { TableColumn } from "react-data-table-component";
import Loader from "../components/Loader";
import { IOrgBalances } from "src/types/settlements";
import { format, shortId, date, customStyles } from "../utils/table";
import EmptyTable from "src/components/EmptyTable";
import formatter from "../utils/format";

const columns: TableColumn<IOrgBalances>[] = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Asset",
    selector: (row) => row.asset,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.net_qty_including_settlement,
    sortable: true,
    format: format(formatter.balanceDecimals, "net_qty_including_settlement"),
  },
];

export default function OrgBalances() {
  const { orgbalances, orgbalancesLoading } = useGetOrganizationBalances();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={orgbalances}
      responsive
      pagination
      dense
      progressPending={orgbalancesLoading}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      fixedHeaderScrollHeight="100%"
      customStyles={customStyles}
    />
  );
}
