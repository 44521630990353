import useSWR from "swr";
import { useMemo } from "react";
// utils
import { fetcher, endpoints, REFRESH_INTERVAL } from "src/utils/axios";
// types
import { IRequestForQuote } from "src/types/request-for-quote";

// ----------------------------------------------------------------------

export function useGetRequestForQuotes() {
  const URL = endpoints.requestForQuotes.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
  });

  const memoizedValue = useMemo(
    () => ({
      requestForQuotes: (data as IRequestForQuote[]) || [],
      requestForQuotesLoading: isLoading,
      requestForQuotesError: error,
      requestForQuotesValidating: isValidating,
      requestForQuotesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
