import { useGetTransfers } from "../api/balances";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import formatter from "../utils/format";
import { ITransfer } from "src/types/balance";
import { format, date, customStyles } from "../utils/table";
import EmptyTable from "src/components/EmptyTable";
import { useState } from "react";
import axios from "../utils/axios";

const formatUnixTimestamp = (timestamp: number): string => {
  const dateObj = new Date(timestamp);
  return dateObj.toLocaleString();
};

const ExpandedComponent: React.FC<ExpanderComponentProps<ITransfer>> = ({
  data,
}) => {
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

export default function Instances() {
  const saveMemoToDatabase = async (
    id: number,
    memo: string,
    verdict: boolean
  ) => {
    console.log(
      `Saving memo ${memo} for item with ID ${id}... with verdict ${verdict}`
    );
    axios
      .post(`/transfers/record`, {
        id,
        memo,
        verdict,
      })
      .then((response) => {
        if (response.status === 200) {
          window.alert("Record added successfully!");
        } else {
          window.alert("Failed to add the record.");
        }
      })
      .catch((error) => {
        console.log(error);
        console.error("There was an error!", error);
        window.alert("Error: Could not add the record.");
      });
  };

  const handleConfirm = (row: ITransfer) => {
    saveMemoToDatabase(row.id, rowData[row.id]?.memo || "", true);
  };

  const handleReject = (row: ITransfer) => {
    saveMemoToDatabase(row.id, rowData[row.id]?.memo || "", false);
  };

  const handleMemoChange = (row: ITransfer, value: string) => {
    setRowData((prevRowData) => ({
      ...prevRowData,
      [row.id]: {
        ...prevRowData[row.id],
        memo: value,
      },
    }));
  };

  const isMemoEmpty = (memo: string | undefined) => !memo || memo.trim() === "";
  const [rowData, setRowData] = useState<{
    [key: number]: { memo: string; verdict: boolean };
  }>({});

  const columns: TableColumn<ITransfer>[] = [
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Asset",
      selector: (row) => row.asset,
      sortable: true,
    },
    {
      name: "Exchange",
      selector: (row) => row.venue,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.amount,
      sortable: true,
      format: format(formatter.decimal, "amount"),
    },
    {
      name: "Timestamp",
      selector: (row) => formatUnixTimestamp(row.activityTimestamp),
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => row.dateCreated,
      sortable: true,
      format: date("dateCreated"),
    },
    // {
    //   name: "Memo",
    //   cell: (row) => (
    //     <input
    //       type="text"
    //       className="py-2 px-4 text-black"
    //       value={rowData[row.id]?.memo || ""}
    //       onChange={(e) => handleMemoChange(row, e.target.value)}
    //     />
    //   ),
    // },
    {
      name: "MEMO",
      cell: (row) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <select
            className="py-2 px-4 text-white bg-gray-600"
            value={rowData[row.id]?.memo || ""}
            onChange={(e) => handleMemoChange(row, e.target.value)}
          >
            <option value="" disabled selected>
              Select a memo
            </option>

            <option>TAKE_PROFIT</option>
            <option>DEPOSIT</option>
            <option>WITHDRAWAL</option>
            <option>SETTLEMENT</option>
          </select>
        </div>
      ),
    },
    {
      name: "Valid",
      cell: (row) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <button
            className="bg-primary-500 hover:bg-primary-600 text-white font-medium py-2 px-4"
            onClick={() => handleConfirm(row)}
          >
            Valid
          </button>
        </div>
      ),
      button: true,
    },
    {
      name: "Invalid",
      cell: (row) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <button
            className="bg-primary-500 hover:bg-primary-600 text-white font-medium py-2 px-4"
            onClick={() => handleReject(row)}
          >
            Invalid
          </button>
        </div>
      ),
      button: true,
    },
  ];
  const { transfers, transfersLoading } = useGetTransfers();
  return (
    <DataTable
      theme="qf"
      columns={columns}
      data={transfers}
      responsive
      pagination
      dense
      progressPending={transfersLoading}
      // expandableRows
      expandableRowsComponent={ExpandedComponent}
      progressComponent={<EmptyTable columns={columns} />}
      fixedHeader
      // highlightOnHover
      customStyles={customStyles}
    />
  );
}
